export const API = {
    ENQUIRY: {
        ENQUIRY_NUMBER: "enquiryNumber",
        PERSONAL: {
            PHOTO: "photo",
            PREFIX: "prefix",
            LAST_NAME: "lastName",
            MIDDLE_NAME: "middleName",
            FIRST_NAME: "firstName",
            HOUSE_NO: "houseNo",
            ADDRESS1: "address1",
            ADDRESS2: "address2",
            LANDMARK: "landmark",
            PINCODE: "pincode",
            CITY: "city",
            STATE: "state",
            DATE_OF_BIRTH: "dateOfBirth",
            GENDER: "gender",
        },
        EDUCATIONAL: {
            STANDARD_ID: "standardId",
            PASS_FROM: "passfrom",
            SCHOOL_TIME: "schoolTime",
            LAST_YEAR_PERCENTAGE: "lastYearPercentage",
            SCHOOL: "school",
            SUBJECTS: "subjects",
            SUBJECT: "subject",
            FEES: "fees",
            DISCOUNT: "discount",
            TOTAL_AMOUNT: "totalAmount",
            REMARK_FOR_FEES: "remarkForFees",
            REMARK_FOR_STUDENT: "remarkForStudent",
            REFERENCE: "reference",
        },
        GUARDIAN: {
            PARENT_PREFIX: "parentPrefix",
            PARENT_LAST_NAME: "parentLastName",
            PARENT_MIDDLE_NAME: "parentMiddleName",
            PARENT_FIRST_NAME: "parentFirstName",
            CONTACT1: "contact1",
            SMS_ON1: "smsOn1",
            CONTACT2: "contact2",
            SMS_ON2: "smsOn2",
            OCCUPATION: "occupation",
            SIBLINGS: "siblings",
            SIBLING_PREFIX: "siblingPrefix",
            SIBLING_LAST_NAME: "siblingLastName",
            SIBLING_FIRST_NAME: "siblingFirstName",
            SIBLING_MIDDLE_NAME: "siblingMiddleName",
            SIBLING_CONTACT1: "siblingContact1",
            STUDY_HERE: "studyHere",
        },
    },
    MOBILE: {
        MOBILE_NUMBER: "mobileNumber",
    },
    ADMISSION_CONFIRM: {
        STUDENT_ID: "studentId",
        ADMISSION_DATE: "addmissionDate",
        BATCH_ID: "batchId",
        SHIFT_ID: "shiftId",
        DATE: "date",
        AMOUNT: "amount",
        MODE: "mode",
        CHEQUE_NUMBER: "chequeNumber",
        RESON: "reason",
    },
};
